<template>
  <div v-if="mCart">
    <nav v-if="mCart" class="navbar">
      <a @click="$router.go(-1)" class="navbar-item">
        <span class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </span>
        <span>{{ $t('Components.Carts.CartDetail.Navbar_Link_back') }}</span>
      </a>
    </nav>

    <div v-if="!isFinalized">
      <section v-if="mCart" class="section is-small">
        <div class="container">
          <div v-if="mCart && mCart.CartType !== 1">
            <article
              class="message is-warning has-margin-top has-margin-bottom"
            >
              <div class="message-body">
                {{ $t('Components.Carts.CartDetail.Expires-in') }}
                {{ msToTime(expiresOn) }}
              </div>
            </article>
          </div>

          <div class="columns">
            <div class="column">
              <cart-basic />
            </div>

            <div class="column">
              <cart-invoice-address />
            </div>
          </div>
        </div>
      </section>

      <section class="section is-small">
        <div class="container">
          <cart-meetingspaces
            v-if="mCart.MeetingTypeId === 1"
            :languageCulture="locationState.Settings.LanguageCulture"
          />

          <cart-workspaces v-if="mCart.MeetingTypeId === 2" :cart="mCart" />
        </div>
      </section>

      <section class="section is-small">
        <div class="container">
          <cart-options
            :languageCulture="locationState.Settings.LanguageCulture"
          />
        </div>
      </section>

      <section class="section is-small">
        <div class="container">
          <cart-totals
            :languageCulture="locationState.Settings.LanguageCulture"
            :key="'ct' + timestamp"
          ></cart-totals>
        </div>
      </section>

      <section class="section">
        <div class="container has-text-right">
          <a @click="deleteCart" class="has-margin-right">{{
            $t('Components.Carts.CartDetail.Link_Cancel-cart')
          }}</a>

          <a
            @click="finalizeCart"
            v-if="mCart.CartType !== 'DoubleBooking'"
            class="button glowing-pulse is-success createButton"
            :class="{ 'is-loading': isFinalizing }"
            >{{
              $t('Components.Carts.CartDetail.Button_Create-reservation')
            }}</a
          >
        </div>
      </section>
    </div>

    <div v-if="isFinalized">
      <section v-if="isFinalizingSuccess" class="section">
        <div class="container has-text-centered">
          <success-animation />

          <div class="title">Reservation # {{ reservation.Id }} created</div>
        </div>
      </section>

      <section v-if="isFinalizingError" class="section">
        <div class="container has-text-centered">
          <error-animation />

          <div class="title">Oops something went wrong</div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import cartProvider from '@/providers/cart'
import { EventBus } from '@/eventbus/event-bus'

const CartBasicData = () => import('@/components/Carts/CartBasicData')
const CartInvoiceAddress = () => import('@/components/Carts/CartInvoiceAddress')
const CartMeetingspaces = () => import('@/components/Carts/CartMeetingspaces')
const CartOptions = () => import('@/components/Carts/CartOptions')
const CartWorkspaces = () => import('@/components/Carts/CartWorkspaces')
const CartTotals = () => import('@/components/Carts/CartTotals')

const SuccessAnimation = () =>
  import('@/components/UI/Components/SuccessAnimation')
const ErrorAnimation = () => import('@/components/UI/Components/ErrorAnimation')

export default {
  components: {
    'cart-basic': CartBasicData,
    'cart-invoice-address': CartInvoiceAddress,
    'cart-meetingspaces': CartMeetingspaces,
    'cart-options': CartOptions,
    'cart-workspaces': CartWorkspaces,
    'cart-totals': CartTotals,
    'error-animation': ErrorAnimation,
    'success-animation': SuccessAnimation,
  },

  props: {},

  data() {
    return {
      cartKey: '',
      cartProfile: {
        CartKey: '',
        ProfileId: 0,
        ProfileKey: '',
        ProfileName: '',
        ProfileImage: '',
      },
      cartProfiles: [],
      currentDateTime: new Date().getTime(),
      expireDateTime: 0,
      expiresOn: 0,
      drawerTitle: '',
      isDeleting: false,
      isFinalized: false,
      isFinalizing: false,
      isFinalizingSuccess: false,
      isFinalizingError: false,
      isSaving: false,
      locationState: this.$store.getters['locationStore/getLocationState'],
      mCart: null,
      modalTitle: '',
      selectedOption: null,
      showOptionSummary: false,
      showSideDrawer: false,
      timestamp: new Date().getTime(),
    }
  },

  created() {
    this.mCart = JSON.parse(JSON.stringify(this.cart))

    this.activateCountDown()
  },

  computed: {
    ...mapState('cartStore', ['cart']),
    ...mapGetters({ profile: 'getProfile' }),

    resStart() {
      let minStartMinutes = 0
      if (this.cart && this.cart.Spaces.length > 0) {
        var lowest = Number.POSITIVE_INFINITY
        var tmp
        for (var i = this.cart.Spaces.length - 1; i >= 0; i--) {
          tmp = this.cart.Spaces[i].StartMinutes
          if (tmp < lowest) {
            lowest = tmp
          }
        }
        minStartMinutes = lowest
      }
      return minStartMinutes
    },

    resEnd() {
      let maxEndMinutes = 0
      if (this.cart && this.cart.Spaces.length > 0) {
        var highest = Number.NEGATIVE_INFINITY
        var tmp
        for (var i = this.cart.Spaces.length - 1; i >= 0; i--) {
          tmp = this.cart.Spaces[i].EndMinutes
          if (tmp > highest) {
            highest = tmp
          }
        }
        maxEndMinutes = highest
      }
      return maxEndMinutes
    },
  },

  mounted() {
    let self = this

    if (self.mCart) {
      self.cartKey = self.mCart.CartKey
    }
  },

  beforeDestroy() {
    let self = this

    self.setCart(null)
  },

  methods: {
    ...mapMutations('cartStore', ['setCart']),

    activateCountDown() {
      let self = this

      self.expireDateTime =
        self.cart.CreatedOn + 60000 * self.cart.MinutesToExpire
      self.expiresOn = self.expireDateTime - self.currentDateTime

      self.timer = window.setInterval(self.countdown, 1000)
    },

    countdown() {
      let expiresOn = this.expiresOn
      this.expiresOn = expiresOn - 1000
      if (this.expiresOn <= 0) {
        this.clearTimer()
      }
    },

    msToTime(s) {
      let ms = s % 1000
      s = (s - ms) / 1000
      let secs = s % 60
      s = (s - secs) / 60
      let mins = s % 60
      let hrs = (s - mins) / 60

      return (
        ('0' + hrs).slice(-2) +
        ':' +
        ('0' + mins).slice(-2) +
        ':' +
        ('0' + secs).slice(-2)
      )
    },

    clearTimer() {
      window.clearInterval(this.timer)
    },

    addOption(option) {
      let self = this

      self.mCart.Options.push({
        CartId: Number(self.mCart.Id),
        OptionId: option.OptionId,
        OptionName: option.OptionName,
        Image: option.OptionImage,
        CategoryId: option.CategoryId,
        Amount: option.Amount,
        CurrencyId: option.CurrencyId,
        PricePerItem: option.PricePerItem,
        PriceTotal: option.PriceTotal,
        Crc: option.Crc,
        TaxId: option.TaxId,
        TaxPercentage: option.TaxPercentage,
        Id: 0,
        ChangeAllInSet: option.ChangeAllInSet,
        IsPP: option.IsPP,
        IsPerHour: option.IsPerHour,
        SelectedTime: option.SelectedTime,
        SpaceId: option.SpaceId,
      })

      let t = setTimeout(() => {
        self.saveCart()
        self.setVisibleModalItem('')
        clearTimeout(t)
      }, 0)
    },

    saveCart() {
      let self = this
      self.isSaving = true
      self.timestamp = new Date().getTime()

      cartProvider.methods
        .updateCart(self.mCart)
        .then((response) => {
          self.mCart = response.data
          self.setCart(response.data)

          EventBus.$emit('showToast', {
            type: 'info',
            message: 'Cart is saved!',
          })
        })
        .finally(() => {
          self.isSaving = false
        })
    },

    finalizeCart() {
      let self = this
      self.isFinalizing = true

      cartProvider.methods
        .finalizeCart(self.mCart.CartKey)
        .then((response) => {
          if (response.status === 201) {
            this.reservation = response.data

            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Reservation #' + this.reservation.Id + ' created!',
            })

            this.isFinalizingSuccess = true
          }
        })
        .catch((error) => {
          this.isFinalizingError = true

          EventBus.$emit('showToast', {
            type: 'warning',
            message: 'Something went wrong, reservation is not created!',
          })
        })
        .finally(() => {
          self.isFinalizing = false
          this.isFinalized = true
        })
    },

    deleteCart() {
      let self = this
      let cartKey = self.mCart.CartKey
      cartProvider.methods.cancelCart(cartKey).then((response) => {
        //self.mCart = null;
        self.$emit('cartCancelled', cartKey)

        EventBus.$emit('showToast', {
          type: 'info',
          message: 'Cart is cancelled!',
        })

        self.$router.go(-1)
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
